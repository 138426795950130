import React from "react"

const IconArrowDown = ({ fill }) => (
  <svg width="12" height="13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.003 6.544a.328.328 0 010 .464l-5.771 5.77a.328.328 0 01-.464 0l-5.77-5.77a.328.328 0 010-.464l.192-.193a.328.328 0 01.464 0l4.881 4.88V.953c0-.181.147-.328.328-.328h.274c.18 0 .328.147.328.328v10.278l4.88-4.88a.328.328 0 01.465 0l.193.193z"
      fill={fill}
    />
  </svg>
)

export default IconArrowDown
