import React from "react"
import styled from "styled-components"
import Inner from "../../layout/pageInner/Inner"
import BlogCard from "./BlogCard"
import CategorySidebar from "./CategorySidebar"
import Pagination from "./Pagination"

const BlogLayout = ({
  posts,
  pageNumber,
  pageTitle = "All Posts",
  categorySlug,
  numberOfPages,
}) => {
  return (
    <Container>
      <Inner>
        <Grid>
          <Left>
            <Title dangerouslySetInnerHTML={{ __html: pageTitle }} />
            <PostContainer>
              {posts.map((post, i) => (
                <BlogCard post={post} key={post.id} />
              ))}
            </PostContainer>
            <Pagination
              pageNumber={pageNumber}
              numberOfPages={numberOfPages}
              baseURL={
                categorySlug
                  ? "/sleep-matters/category/" + categorySlug + "/"
                  : "/sleep-matters/"
              }
            />
          </Left>
          <Right>
            <CategorySidebar activeCategory={categorySlug} />
          </Right>
        </Grid>
      </Inner>
    </Container>
  )
}

export default BlogLayout

const Container = styled.div`
  background: #f6f6f6;
  padding: 60px 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const Left = styled.div`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    order: 1;
  }
`
const Right = styled.div`
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    order: 0;
  }
`

const Title = styled.h4`
  color: #223247;
  font-size: 31px;
  font-weight: 300;
  margin-bottom: 10px;
`

const PostContainer = styled.div``
