import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowLeft from "../../atoms/icons/files/arrow-left.jsx"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import { urlWithParamValue } from "../../../lib/util"

const Pagination = ({
  pageNumber,
  numberOfPages,
  useQueryParam = false,
  baseURL = "/sleep-matters/",
  pagesOnTheEdge = 1,
}) => {
  const hasNext = pageNumber < numberOfPages
  const hasPrevious = pageNumber > 1

  // Prepare blog URL
  let previousUrl =
    pageNumber === 2 ? baseURL : baseURL + "page-" + (pageNumber - 1)
  let nextUrl = baseURL + "page-" + (pageNumber + 1)

  // Use query URL if applicable
  previousUrl = useQueryParam
    ? urlWithParamValue("page", pageNumber - 1)
    : previousUrl
  nextUrl = useQueryParam ? urlWithParamValue("page", pageNumber + 1) : nextUrl

  return (
    <Wrap>
      <Helmet>
        {pageNumber > numberOfPages && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        {hasPrevious && (
          <link
            rel="prev"
            href={`${process.env.GATSBY_FRONTEND_URL}${previousUrl}`}
          />
        )}
        {hasNext && (
          <link
            rel="next"
            href={`${process.env.GATSBY_FRONTEND_URL}${nextUrl}`}
          />
        )}
      </Helmet>
      <Left>
        <Button
          disabled={!hasPrevious}
          direction="left"
          SvgComponent={ArrowLeft}
          to={previousUrl}
          label="Previous"
        />
      </Left>
      <Center className="center">
        <Numbers
          pageNumber={pageNumber}
          numberOfPages={numberOfPages}
          baseURL={baseURL}
          useQueryParam={useQueryParam}
          pagesOnTheEdge={pagesOnTheEdge}
        />
      </Center>
      <Right>
        <Button
          disabled={!hasNext}
          direction="right"
          SvgComponent={ArrowRight}
          to={nextUrl}
          label="Next"
        />
      </Right>
    </Wrap>
  )
}

const Numbers = ({
  pageNumber,
  numberOfPages,
  baseURL = "/sleep-matters/",
  useQueryParam = false,
  pagesOnTheEdge = 1,
}) => {
  if (numberOfPages === 1) return null
  let pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1)

  const firstPage = 1
  const lastPage = pageNumbers.length

  // The start/end ... parts of the Numbers
  let start = 0
  let end = pageNumbers.length
  if (pageNumber > pagesOnTheEdge + 2) {
    start = pageNumber - pagesOnTheEdge - 1
  }
  if (pageNumbers.length - pageNumber > pagesOnTheEdge + 1) {
    end = pageNumber + pagesOnTheEdge
  }

  // Slice the page section
  pageNumbers = pageNumbers.slice(start, end)

  const buildLink = (num) => {
    const newUrl = num === 1 ? baseURL : baseURL + "page-" + num
    return useQueryParam ? urlWithParamValue("page", num) : newUrl
  }

  return (
    <>
      <MobileNumberIndicator>
        page {pageNumber} of {numberOfPages}
      </MobileNumberIndicator>
      {start && (
        <>
          <Number to={buildLink(firstPage)}>{firstPage}</Number>
          <NumberBlank as="div">...</NumberBlank>
        </>
      )}
      {pageNumbers.map((n) => (
        <Number
          to={buildLink(n)}
          key={n}
          className={n === pageNumber && "active"}
        >
          {n}
        </Number>
      ))}
      {end != lastPage && (
        <>
          <NumberBlank as="div">...</NumberBlank>
          <Number to={buildLink(lastPage)}>{lastPage}</Number>
        </>
      )}
    </>
  )
}

const MobileNumberIndicator = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: flex;
    align-items: center;
    color: white;
  }
`

const Button = ({ SvgComponent, direction, to, label, disabled }) => {
  const elements = [
    <SvgIcon
      SvgComponent={SvgComponent}
      hue="danger"
      shade="fontColor"
      key="icon"
    />,
    <div key="label">{label}</div>,
  ]
  const isLeft = direction === "left"

  return (
    <StyledButton
      className={direction}
      disabled={disabled}
      to={disabled ? "/" : to}
      as={disabled ? "span" : Link}
    >
      {isLeft ? elements : [elements[1], elements[0]]}
    </StyledButton>
  )
}

const StyledButton = styled(Link)`
  border-radius: 3px;
  overflow: hidden;
  background-color: #3e4b5c;
  color: white;
  display: flex;
  width: 200px;
  justify-content: space-between;
  transition: 0.2s all ease-in-out;
  &:hover {
    &.left {
      transform: translateX(-3px);
    }
    &.right {
      transform: translateX(3px);
    }
  }

  ${({ disabled }) =>
    disabled
      ? `
  pointer-events: none;
  background: #162232;
  color:#4c5e75;
  > span.icon {
    background: #162232;
    svg g#icon {
      fill:#4c5e75;
    }
  }
`
      : ``}

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    padding: 10px;
    background: #d91a1f;
  }
`

const Wrap = styled.div`
  background-color: #162232;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .left,
    .right {
      width: auto;
      div {
        display: none;
      }
    }
  }
`

const Left = styled.div``
const Center = styled.div`
  display: flex;
`
const Right = styled.div``

const NumberBlank = styled.div`
  color: white;
  padding: 10px 5px;
  transition: 0.2s all ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

const Number = styled(Link)`
  background-color: #3e4b5c;
  border-radius: 3px;
  color: white;
  padding: 10px;
  margin: 0 3px;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
  &.active {
    background: #d91a1f;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
`

export default Pagination
