import React from "react"
import styled from "styled-components"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowDown from "../../atoms/icons/files/arrow-down.jsx"
import { graphql, useStaticQuery } from "gatsby"
import { BgImage } from "gbimage-bridge"
import { path } from "../../../lib/util"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

const CategorySidebar = ({ activeCategory }) => {
  const {
    allLocalWpGraphQlCategories: { nodes },
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = useStaticQuery(CATEGORIES)
  const getImage = path([
    "Image",
    "image",
    "imageFile",
    "localFile",
    "childImageSharp",
    "gatsbyImageData",
  ])
  const categories = nodes.filter(({ posts }) => posts.nodes.length)

  return (
    <Container>
      <Title>
        <SvgIcon SvgComponent={ArrowDown} hue="ocean" shade="080" />
        <h5>Select a Category</h5>
      </Title>
      <LinksContainer>
        {categories.map((category, i) => (
          <Option
            key={i}
            className={activeCategory === category.slug && "active"}
            as={Link}
            to={"/sleep-matters/category/" + category.slug}
          >
            <OptionWithBackground
              image={getImage(category) || gatsbyImageData}
              className={activeCategory === category.slug && "active"}
              name={category.name}
            />
          </Option>
        ))}
      </LinksContainer>
    </Container>
  )
}

export default CategorySidebar

const OptionWithBackground = ({ image, name, className }) => (
  <OptionBackground image={image} className={className}>
    <Overlay className={className}>
      <span className="text" dangerouslySetInnerHTML={{ __html: name }} />
    </Overlay>
  </OptionBackground>
)

const Container = styled.div``

const OptionBackground = styled(BgImage)`
  transition: 0.2s all ease-in-out;
  width: 100%;
`
const Overlay = styled.div`
  transition: 0.2s all ease-in-out;
  color: #ffffff;
  background: linear-gradient(66deg, #223247 0%, #223247 100%);

  &.active {
    color: #0aa9eb;
    span {
      color: #0aa9eb;
    }
  }
  &.active,
  &:hover {
    background: linear-gradient(
      66deg,
      rgba(22, 34, 50, 0.96) 0%,
      rgba(34, 50, 71, 0.48) 100%
    );
  }

  span {
    transition: 0.2s all ease-in-out;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  .icon {
    margin-right: 10px;
  }
  h5 {
    color: #223247;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
  }
`

const LinksContainer = styled.div`
  border-radius: 2px;
  overflow: hidden;
`

const Option = styled.div`
  color: #bac0c5;
  font-size: 20px;
  background: #223247;
  margin-bottom: 3px;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2s all ease-in-out;
  span.text {
    padding: 20px 15px;
    display: block;
  }
  &.active:before {
    content: "";
    position: absolute;
    left: -14px;
    color: black;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #0aa9eb;
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      display: none;
    }
  }
  &:hover {
    /* transform: translateX(3px); */
  }
`

const CATEGORIES = graphql`
  {
    allLocalWpGraphQlCategories {
      nodes {
        slug
        name
        posts {
          nodes {
            id
          }
        }
        Image {
          image {
            sourceUrl
            mediaItemId
            modified
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }

    file(relativePath: { eq: "placeholders/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
  }
`
